@import "./scss/index.scss";

.btn {
  background-color: $label-letter-color !important;
  border: none !important;
}

#root {
  background-color: #fafafa !important;
}

h1 {
  color: $title-letter-color !important;
}

h2 {
  color: $label-letter-title-color !important;
}

h4 {
  color: $label-letter-title-color !important;
}

label {
  color: $label-letter-color !important;
}

.classContainer {
  background-color: $background-color !important;
}

.classFromLogin {
  background-color: $background-login-color !important;
}

.card-header {
  background-color: $background-title-color !important;
}

.nav-item {
  background-color: $background-title-color !important;
}

.nav-item .active {
  border-style: solid;
  text-decoration: underline $label-letter-color !important;
  border-color: $label-letter-color !important;
  background-color: $background-color !important;
}

.nav-item .active h4 {
 color: $label-letter-color !important;
}

.nav-link {
  border-width: 2px !important;
  border-color: #fafafa !important;
}

.button-group-item{
  background-color: #000 !important;
  color: #fff !important;
  border: 1px solid #424649 !important;
}

.button-group-selected-item{
  background-color: #424649 !important;
  color: #fff !important;
  border: 1px solid #000 !important;
}